<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('employee.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('employee.pageTitle'),
                        href: '/admin/employees'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-3 tw-mb-5">
                        <profile
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                        />
                    </div>
                    <div class="col-md-9 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <form
                                    class="form-horizontal form-horizontal-text-right"
                                >
                                    <details-template
                                        ref="detial"
                                        v-model="model"
                                        :validation="errors"
                                    />
                                    <Tabs v-model="tabValue">
                                        <TabPane
                                            label="DOCUMENTS"
                                            name="documents"
                                        >
                                            <document-template
                                                v-if="tabValue == 'documents'"
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                        <TabPane
                                            label="EDUCATION HISTORY"
                                            name="education_history"
                                        >
                                            <education-history
                                                v-if="
                                                    tabValue ==
                                                        'education_history'
                                                "
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                        <TabPane
                                            label="EMPLOYMENT HISTORY"
                                            name="employment_history"
                                        >
                                            <employment-history
                                                v-if="
                                                    tabValue ==
                                                        'employment_history'
                                                "
                                                v-model="model"
                                                :validation="errors"
                                            />
                                        </TabPane>
                                    </Tabs>
                                    <payroll-template
                                        v-model="model"
                                        :validation="errors"
                                    />
                                    <attach-file
                                        v-model="model"
                                        :validation="errors"
                                    />
                                </form>
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'employee' })"
                :disabled="waiting_new || waiting"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting_new || waiting"
                @click.prevent="onSaveAddNew"
            >
                {{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new || waiting"
                @click.prevent="onSave"
            >
                {{ $t("save") }}</ts-button
            >
        </div>
        <Modal v-model="already_exists_profile" width="360">
            <p slot="header" style="color:#49b6d6;text-align:center">
                <Icon type="ios-information-circle"></Icon>
                <span>Employee already exists</span>
            </p>
            <div class="d-flex">
                <a class="w-60px tw-no-underline tw-text-center" href="#">
                    <img
                        class="mw-100"
                        v-lazy="{
                            src: photo(employee),
                            error:
                                employee.sex == 'Male'
                                    ? require('@/assets/staff-default-man.png')
                                    : require('@/assets/staff-default-women.png'),
                            loading: require('@/assets/Rolling-1s-200px.svg'),
                            preLoad: 1.3,
                            attempt: 1
                        }"
                    />
                    <span>{{ employee.card_id }}</span>
                </a>
                <div class="ps-3 flex-1">
                    <h5 class="mb-1">{{ employee.employee_name_en }}</h5>
                    <p class="mb-0">
                        {{
                            employee.logical_position
                                ? employee.logical_position.position_name_en
                                : ""
                        }}
                    </p>
                    <p class="mb-0">
                        {{
                            employee.branch
                                ? employee.branch.branch_name_en
                                : ""
                        }}
                    </p>
                    <p class="mb-0">
                        <span
                            class="badge bg-success"
                            v-if="employee.status_id == 1"
                            >{{ $t("employee.active") }}</span
                        >
                        <span
                            class="badge bg-danger"
                            v-if="employee.status_id == 2"
                            >{{ $t("employee.resigned") }}</span
                        >
                        <span
                            class="badge bg-info"
                            v-if="employee.status_id == 3"
                            >{{ $t("employee.terminated") }}</span
                        >
                        <span
                            class="badge bg-warning"
                            v-if="employee.status_id == 4"
                            >{{ $t("employee.cancelled") }}</span
                        >
                        <span
                            class="badge bg-secondary"
                            v-if="employee.status_id == 5"
                            >{{ $t("employee.other") }}</span
                        >
                    </p>
                </div>
            </div>
            <div slot="footer">
                <Button
                    type="info"
                    size="large"
                    long
                    @click.prevent="onEdit(employee)"
                    >Edit Profile</Button
                >
            </div>
        </Modal>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Profile from "./components/profile.vue";
import DocumentTemplate from "./components/document.vue";
import EducationHistory from "./components/education-history.vue";
import EmploymentHistory from "./components/employment-history.vue";
import DetailsTemplate from "./components/detail";
import PayrollTemplate from "./components/payroll";
import AttachFile from "./components/attach-file.vue";
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";
import { isEmpty } from "lodash";

export default {
    name: "employeeCreate",
    components: {
        Profile,
        DocumentTemplate,
        EducationHistory,
        EmploymentHistory,
        DetailsTemplate,
        AttachFile,
        PayrollTemplate
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            already_exists_profile: false,
            employee: {},
            errors: new Errors(),
            tabValue: "documents",
            model: {
                employee_name_kh: null,
                employee_name_en: null,
                sex: null,
                phone: null,
                card_id: null,
                email: null,
                address: null,
                photo: null,
                position_id: null,
                hire_date: moment().format("DD-MM-YYYY"),
                probation_end_date: null,
                status_id: 1,
                status_effective_date: null,
                shift_id: null,
                pc_date_id: null,
                payment_type_id: null,
                is_tax_responsed_by_company: false,
                is_spouse_dependant: false,
                num_child_dependant: 0,
                nssf_number: null,
                ssn_no: null,
                ssn_issued_date: null,
                ssn_expiry_date: null,
                ssn_issued_at: null,
                nationality_id: 2,
                date_of_birth: null,
                emp_type_id: 1,
                status_reason_id: null,
                fp_serial_card: null,
                num_of_payroll: 1,
                branch_id: null,
                province_code: null,
                disctrict_code: null,
                commune_code: null,
                village_code: null,
                is_employer: false,
                is_audit: false,
                details: [],
                education_histories: [],
                employment_histories: [],
                attach_file_list: [],
                is_sm: false
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("humanResource/employee", [
            "getPosition",
            "getStatus",
            "getStatusReason",
            "getShift",
            "getPcDate",
            "getPaymentType",
            "getNationality"
        ]),
        async fetchResource() {
            this.loading = true;
            this.getPosition();
            this.getStatus();
            this.getStatusReason();
            this.getShift();
            this.getPcDate();
            this.getPaymentType();
            await this.getNationality();
            this.$refs.detial.fetchBranch();
            this.$refs.detial.onChangeHireDate(this.model.hire_date);
            this.$refs.detial.loadEmployeeLm();
            this.loading = false;
        },
        photoUpload() {
            if (
                this.model.photo instanceof File ||
                this.model.photo instanceof Blob
            ) {
                let formData = new FormData();
                formData.append("photo", this.model.photo);
                return this.$store.dispatch(
                    "humanResource/employee/photoUpload",
                    formData
                );
            }

            return this.model.photo;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            let photo_path = await this.photoUpload();
            this.waiting_new = true;
            this.$store
                .dispatch(
                    "humanResource/employee/store",
                    Object.assign({}, this.model, { photo: photo_path })
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if (response.message_id == 0) {
                        this.clearInput();
                    }
                    if (response.message_id == -1) {
                        this.already_exists_profile = true;
                        this.employee = response.data;
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            let photo_path = await this.photoUpload();
            this.waiting = true;
            this.$store
                .dispatch(
                    "humanResource/employee/store",
                    Object.assign({}, this.model, { photo: photo_path })
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if (response.message_id == 0) {
                        this.clearInput();
                        this.$router.push({ name: "employee" });
                    }
                    if (response.message_id == -1) {
                        this.already_exists_profile = true;
                        this.employee = response.data;
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.model.employee_name_kh = null;
            this.model.employee_name_en = null;
            this.model.sex = null;
            this.model.phone = null;
            this.model.card_id = null;
            this.model.email = null;
            this.model.address = null;
            this.model.photo = null;
            this.model.position_id = null;
            this.model.hire_date = moment().format("DD-MM-YYYY");
            this.model.probation_end_date = null;
            this.model.status_id = 1;
            this.model.status_effective_date = null;
            this.model.shift_id = null;
            this.model.pc_date_id = null;
            this.model.payment_type_id = null;
            this.model.is_tax_responsed_by_company = false;
            this.model.is_spouse_dependant = false;
            this.model.num_child_dependant = 0;
            this.model.nssf_number = null;
            this.model.ssn_no = null;
            this.model.ssn_issued_date = null;
            this.model.ssn_expiry_date = null;
            this.model.ssn_issued_at = null;
            this.model.nationality_id = 1;
            this.model.date_of_birth = null;
            this.model.emp_type_id = 1;
            this.model.status_reason_id = null;
            this.model.fp_serial_card = null;
            this.model.num_of_payroll = 1;
            this.model.branch_id = null;
            this.model.province_code = null;
            this.model.disctrict_code = null;
            this.model.commune_code = null;
            this.model.village_code = null;
            this.model.house_no = null;
            this.model.street_no = null;
            this.model.is_employer = false;
            this.model.is_audit = false;
            this.model.is_sm = false;
            this.model.details = [];
            this.model.education_histories = [];
            this.model.employment_histories = [];
            this.model.attach_file_list = [];
        },
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        onEdit(record) {
            if (isEmpty(record)) return;
            this.already_exists_profile = false;
            this.$store.commit("humanResource/employee/SET_EDIT_DATA", record);
            this.$router.push({
                name: "employee-edit",
                params: { id: record.employee_id }
            });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "EMPLOYEE",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
